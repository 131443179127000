<template>
  <div>
    <b-alert variant="danger" :show="ordenData === null && !loading">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        No se encontro la orden solicitada, revise el
        <b-link class="alert-link" :to="{ name: 'ordenes' }">
          listado de ordenes
        </b-link>
        para ver otras ordenes
      </div>
    </b-alert>

    <b-overlay :show="loading" rounded="sm">
      <template v-if="ordenData">
        <b-row class="row equal-cols">
          <b-col cols="12">
            <orden-card
              :orden-data="ordenData"
              :callback-order="callbackChange"
            />
          </b-col>

          <b-col cols="12" lg="4">
            <estado-orden-card
              :orden-id="orderID"
              :estados-data="ordenData.lstEstadosOrden"
            />
          </b-col>

          <b-col cols="12" lg="4">
            <estados-picking-card
              :orden-id="orderID"
              :estados-data="ordenData.lstEstadosPicking"
              :callback-order="callbackChange"
            />
          </b-col>

          <b-col cols="12" lg="4">
            <estado-motorizado-card
              :estados-data="ordenData.lstEstadosMotorizado"
            />
          </b-col>
        </b-row>
        <b-row class="row equal-cols">
          <b-col cols="12" lg="4">
            <cliente-card :orden-data="ordenData" />
          </b-col>

          <b-col cols="12" lg="4">
            <picking-card :orden-data="ordenData" />
          </b-col>

          <b-col cols="12" lg="4">
            <motorizado-card
              :url-gacela="ordenData.tracking_gacela"
              :motorizado-data="ordenData.lstRepartidor"
            />
          </b-col>
        </b-row>
        <b-row class="row equal-cols">
          <b-col cols="12">
            <detalles-card :orden-data="ordenData" />
          </b-col>
        </b-row>
      </template>

      <div style="text-align: center; margin: 45vh 0" v-else>
        <b-spinner label="Loading..." />
      </div>
    </b-overlay>

    <b-modal
      id="modal-error"
      ref="modal-error"
      title="Error"
      ok-only
      centered
      ok-title="Accept"
      ok-variant="danger"
      modal-class="modal-danger"
    >
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BAlert,
  BButton,
  BSpinner,
  BOverlay,
  BCardText,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import moment from "moment";
import OrdenCard from "./components/OrdenCard.vue";
import PickingCard from "./components/PickingCard.vue";
import ClienteCard from "./components/ClienteCard.vue";
import DetallesCard from "./components/DetallesCard.vue";
import MotorizadoCard from "./components/MotorizadoCard.vue";
import EstadoOrdenCard from "./components/EstadoOrdenCard.vue";
import { getAuthToken } from "@/auth/utils";
import EstadosPickingCard from "./components/EstadosPickingCard.vue";
import EstadoMotorizadoCard from "./components/EstadoMotorizadoCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BAlert,
    BButton,
    BSpinner,
    BOverlay,
    BCardText,
    OrdenCard,
    BFormInput,
    BFormGroup,
    PickingCard,
    ClienteCard,
    DetallesCard,
    MotorizadoCard,
    EstadoOrdenCard,
    EstadosPickingCard,
    EstadoMotorizadoCard,
    ToastificationContent,
  },
  data() {
    return {
      error: "",
      socket: null,
      orderID: null,
      loading: true,
      ordenData: null,
      tiempoPreparacion: 10,
    };
  },
  mounted() {
    this.orderID = parseInt(this.$route.path.replace("/picking/orden/", ""));
    if (this.$route.params.orden) {
      this.loading = false;
      this.ordenData = this.$route.params.orden;
      this.socketConnection();
    } else {
      this.getOrderData();
    }
  },
  beforeDestroy() {
    this.socket && this.socket.close(3999, "ComponentDestroy");
  },
  methods: {
    callbackChange() {
      // TODO: Handle - Los cambios de estado llegan por socket, manejar aca solo de ser necesario
    },
    getOrderData() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/OrdenesCallCenter",
          body: JSON.stringify({
            Filas: 10,
            Pagina: 1,
            Estado: "",
            IDOrden: this.orderID,
            TipoOrden: "",
            TipoCliente: "",
            BusquedaAll: "",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.ordenData = res.data.lstOrdenes[0];
            this.socketConnection();
          } else {
            this.error = res.data.mensaje;
            this.$refs["modal-error"].show();
          }
          this.loading = false;
        })
        .catch((e) => {
          this.error = e.message;
          this.$refs["modal-error"].show();
          this.loading = false;
        });
    },
    socketConnection() {
      if (this.socket === null) {
        const eventTime = moment().format("DD/MM/YYYY HH:mm a");
        this.socket = new WebSocket("wss://store3.supereasy.ec:10030");
        this.socket.onopen = this.onSocketOpen;
        this.socket.onclose = this.onSocketClose;
        this.socket.onmessage = this.onNewNotification;
      }
    },
    onSocketOpen(event) {
      const eventTime = moment().format("DD/MM/YYYY HH:mm a");
      this.sendMessage(
        JSON.stringify({
          event: "/ORDER/STATE",
          payload: { orderID: this.orderID },
        })
      );
    },
    onSocketClose(event) {
      const eventTime = moment().format("DD/MM/YYYY HH:mm a");
      if (event.code === 3999) {
        // TODO: Do nothing or handle
      } else {
        this.socket = null;
        this.retryTimeout = setTimeout(this.socketConnection, 10000);
      }
    },
    onNewNotification(event) {
      const message = JSON.parse(event.data);
      const newOrder = message.payload;
      this.ordenData = newOrder;
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: "BellIcon",
          title: "Nuevo estado de la Orden",
          draggable: true,
          closeButton: "button",
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          draggablePercent: 0.6,
          pauseOnFocusLoss: true,
          showCloseButtonOnHover: false,
        },
      });
    },
    sendMessage(message) {
      this.socket.send(message);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
.row.equal-cols {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row.equal-cols:before,
.row.equal-cols:after {
  display: block;
}

.row.equal-cols > [class*="col-"] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row.equal-cols > [class*="col-"] > * {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
</style>
