<template>
  <div class="list-view product-checkout mt-0">
    <!-- Products List -->

    <productos-card
      :estado="ordenData.estadopicking"
      :productos-data="ordenData.lstProductos"
    />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card title="Detalles de pago">
        <div class="price-details">
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">SubTotal</div>
              <div class="detail-amt">${{ ordenData.subtotal.toFixed(2) }}</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">IVA</div>
              <div class="detail-amt">${{ ordenData.iva.toFixed(2) }}</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">Envio</div>
              <div class="detail-amt">
                ${{ ordenData.valor_envio.toFixed(2) }}
              </div>
            </li>
            <li class="price-detail" v-if="ordenData.descuento > 0">
              <div class="detail-title">Descuento</div>
              <div class="detail-amt">
                ${{ ordenData.descuento.toFixed(2) }}
              </div>
            </li>
          </ul>
          <hr />
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">Total</div>
              <div class="detail-amt font-weight-bolder">
                ${{ ordenData.total.toFixed(2) }}
              </div>
            </li>
          </ul>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import ProductosCard from "./ProductosCard.vue";

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    ProductosCard,
  },
  props: {
    ordenData: {
      type: Object,
      required: true,
    },
  },
};
</script>

