var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "datos-cliente",
    attrs: {
      "title": "Datos del Cliente"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Nombre")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.cliente[0].nombre) + " " + _vm._s(_vm.ordenData.cliente[0].apellido) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CreditCardIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Cédula/RUC")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.cliente[0].tipoidentifica) + " - " + _vm._s(_vm.ordenData.cliente[0].identificacion) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Dirección")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.lstDireccionEntrega[0].direccion) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FlagIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Referencia")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.lstDireccionEntrega[0].detalle) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PhoneIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Teléfono")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.cliente[0].telefono) + " ")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }