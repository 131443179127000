var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "mb-0"
        }, [_vm._v("Estados de la Orden")]), _vm.itCanResetState() ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-1",
            modifiers: {
              "modal-1": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Reiniciar estados")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('app-timeline', _vm._l(_vm.dataEstados, function (estado) {
    return _c('app-timeline-item', {
      key: estado.fecha,
      attrs: {
        "icon": _vm.stateIcon(estado.codigo),
        "variant": _vm.stateColor(estado.codigo)
      }
    }, [_c('div', {
      staticClass: "\n            d-flex\n            flex-sm-row flex-column flex-wrap\n            justify-content-between\n            mb-1 mb-sm-0\n          "
    }, [_c('h6', {
      staticClass: "m-0"
    }, [_vm._v(_vm._s(estado.descripcion))])]), _c('p', [_vm._v(_vm._s(_vm.formattedDate(estado.fecha)))])]);
  }), 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-1",
      "title": "Atención"
    },
    on: {
      "ok": _vm.handleOk
    }
  }, [_c('p', {
    staticClass: "my-4"
  }, [_vm._v("Esta acción es "), _c('strong', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("irreversible")]), _vm._v(" y reiniciará todos los estados de la orden. "), _c('strong', [_vm._v("Desea continuar?")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }