var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Productos"
    }
  }, [_c('vue-good-table', {
    attrs: {
      "compactMode": "",
      "columns": _vm.columns,
      "rows": _vm.productosData,
      "search-options": {
        enabled: false
      },
      "pagination-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'recolectado' ? _c('div', {
          class: _vm.typeClass
        }, [_vm.estado === 'C' || _vm.estado === 'IN' ? _c('b-form-spinbutton', {
          attrs: {
            "id": "sb-maxmin",
            "min": "0",
            "max": props.row.stock,
            "size": "sm"
          },
          on: {
            "change": function change($event) {
              return _vm.onChangeCant(props.row[props.column.field], props.row.originalIndex);
            }
          },
          model: {
            value: props.row.recolectado,
            callback: function callback($$v) {
              _vm.$set(props.row, "recolectado", $$v);
            },
            expression: "props.row.recolectado"
          }
        }) : _c('div', [_vm._v(_vm._s(props.row.recolectado))])], 1) : props.column.field === 'precioUnitario' ? _c('div', {
          class: _vm.typeClass
        }, [_vm._v(" $" + _vm._s(props.row.precioUnitario.toFixed(2)) + " ")]) : props.column.field === 'subtotal' ? _c('div', {
          class: _vm.typeClass
        }, [_vm._v(" $" + _vm._s(props.row.subtotal.toFixed(2)) + " ")]) : _c('span', {
          class: _vm.typeClass
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }