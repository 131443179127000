<template>
  <b-card v-if="showCerrarOrden" title="Cerrar orden">
    <b-alert variant="danger" class="mb-0" :show="true">
      <div class="alert-body">
        <feather-icon icon="InfoIcon" class="mr-50" />
        La orden ya fue pickeada
      </div>
    </b-alert>
    <b-button
      block
      class="mt-2"
      @click="closeOrder"
      variant="primary"
      :disabled="loading"
    >
      <b-spinner v-if="loading" small label="Loading..." />
      <div v-else>Cerrar orden MKT</div>
    </b-button>
    <b-modal
      id="modal-cerrar-ordenmkt"
      ref="modal-cerrar"
      title="Confirma que desea cerrar la orden"
      centered
      ok-title="Cerrar"
      @ok="onSubmit"
      cancel-title="No"
      cancel-variant="outline-secondary"
      :hide-footer="orderIsIncomplete"
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="orderIsIncomplete">
        <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        <form @submit.stop.prevent="handleSubmit(onSubmit)" v-else>
          <validation-provider
            name="Correo"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label="Correo"
            >
              <b-form-select 
                v-model="usuariospv"
                :options="supervisors"
                :state="getValidationState(validationContext)"
                value-field="email"
                text-field="email"
                aria-describedby="input-1-live-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Seleccione el correo --</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <validation-provider
            name="Contraseña"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label="Contraseña"
            >
              <b-form-input
                v-model="passspv"
                :state="getValidationState(validationContext)"
                type="password"
                placeholder="Password"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="Observación"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group 
              label="Observación"
            >
              <b-form-input
                :state="getValidationState(validationContext)"
                v-model="observacion"
                placeholder="Observacion/Motivo incompleta..."
                aria-describedby="input-3-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-3-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex justify-content-around">
            <b-button type="submit" variant="primary">Confirmar</b-button>
            <b-button class="ml-2" variant="secondary">Cancelar</b-button>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormRadioGroup,
  BFormSelect,
  BFormInvalidFeedback,
  BFormSelectOption,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BFormSpinbutton,
    ToastificationContent,
    BFormSelect,
    BFormInvalidFeedback,
    BFormSelectOption,
  },
  props: {
    ordenId: {
      type: Number,
      required: true,
    },
    estado: {
      type: String,
      required: true,
    },
    callbackOrder: {
      type: Function,
      required: true,
    },
    ordenData: {
      type: Object,
      required: false,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      observacion: "",
      usuariospv: null,
      passspv: "",
      supervisors: []
    };
  },
  computed: {
    showCerrarOrden() {
      let allowestadoorden = false;
      let allowstadopicking = false;
      if (
        !(
          this.ordenData.estadoorden === "ET" ||
          this.ordenData.estadoorden === "EN" ||
          this.ordenData.estadoorden === "LL"
        )
      ) {
        allowestadoorden = true;
      }
      if (
        this.ordenData.estadopicking === "C" ||
        this.ordenData.estadopicking === "IN"
      ) {
        allowstadopicking = true;
      }
      return allowestadoorden && allowstadopicking;
    },
    orderIsIncomplete() {
      let incomplete = this.ordenData.lstProductos.filter(
        (product) => product.recolectado < product.cantidad
      );
      if (incomplete && incomplete.length > 0) {
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    if (!this.orderIsIncomplete) return;

    this.$http.post(this.$store.state.app.middlewareURL, {
        path: "/Administracion/supervisor",
        body: JSON.stringify({
          bodega: this.ordenData.idbodega,
          tokenSesion: getAuthToken(),
        }),
      })
      .then((res) => {
        if (res.data.bOk) {
          this.supervisors = res.data.data;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    closeOrder(event) {
      event && event.preventDefault();
      if (!this.orderIsIncomplete) {
        this.passspv = "";
        this.usuariospv = "";
        this.observacion = "";
      }
      this.showModalCerrarOrden();
    },
    showModalCerrarOrden() {
      this.$refs["modal-cerrar"].show();
    },
    buildParams() {
      const params = {};
      params["id"] = this.ordenId;
      params["flash"] = this.ordenData.tipoorden === "UF" || this.ordenData.tipoorden === "FS";
      params["bodega"] = this.ordenData.idbodega;
      params["completa"] = !this.orderIsIncomplete;
      params["ClaveSup"] = this.passspv;
      params["IDUsuario"] = 0;
      params["productos"] = this.getArrayItemsRecolectedFormat();
      params["UsuarioSup"] = this.usuariospv ?? "";
      params["CerrarOrden"] = true;
      params["Observacion"] = this.observacion;
      params["tokenSesion"] = getAuthToken();

      return params;
    },
    getArrayItemsRecolectedFormat() {
      return this.ordenData.lstProductos.map((item) => ({
        id: item.producto_id,
        cantidad: item.recolectado,
      }));
    },
    getItemsCurrentIncompletos() {
      let productos_con_faltantes = this.ordenData.lstProductos.filter(
        (product) => product.recolectado < product.cantidad
      );
      return productos_con_faltantes;
    },
    onSubmit() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CerrarOrdenMiTienda",
          body: JSON.stringify(this.buildParams()),
        })
        .then((res) => {
          this.loading = false;
          this.$refs["modal-cerrar"].hide();
          if (res.data.bOk) {
            this.callbackOrder();
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se cerro correctamente la orden.",
                title: "Exito",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al cambiar el estado de la orden(${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al cambiar el estado de la orden(${e.message}).`,
              title: "Error",
              variant: "danger",
            },
          });
          this.loading = false;
        });
    }
  },
};
</script>
<style lang="scss">
</style>
