var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-group', {
    staticClass: "mb-2",
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', {
    staticClass: "card-apply-job",
    attrs: {
      "title": "Orden"
    }
  }, [_c('div', {
    staticClass: "apply-job-package bg-light-primary rounded m-0"
  }, [_c('h2', {
    staticClass: "m-0"
  }, [_c('sup', {
    staticClass: "text-body"
  }, [_c('small', [_vm._v("#")])]), _vm._v(_vm._s(_vm.ordenData.idorden) + " ")])]), _vm.ordenData.numero_orden_se1 ? _c('div', {
    staticClass: "apply-job-package bg-light-secondary rounded m-0"
  }, [_c('strong', {
    staticClass: "m-0"
  }, [_vm._v(" Orden Super Easy 1: " + _vm._s(_vm.ordenData.numero_orden_se1) + " ")])]) : _vm._e(), _vm.ordenData.promocode !== '' ? _c('div', {
    staticClass: "bg-light-info rounded mt-1 mb-0 p-1"
  }, [_c('small', [_vm._v("Promocode")]), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.ordenData.promocode) + " ")])]) : _vm._e()]), _c('b-card', {
    attrs: {
      "title": "Fecha de Creacion"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.ordenData.fechacreacion)) + " ")]), _c('small', [_vm._v(_vm._s(_vm.formattedHour(_vm.ordenData.fechacreacion)))])])], 1)], 1), _c('b-card', {
    attrs: {
      "title": "Tipo de entrega"
    }
  }, [_c('h4', [_vm.ordenData.tipoentrega === 'PG' ? _c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClockIcon",
      "size": "18"
    }
  }), _vm._v(" Programada ")], 1) : _vm.ordenData.tipoentrega === 'SA' ? _c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ZapOffIcon",
      "size": "18"
    }
  }), _vm._v(" Sin Apuros ")], 1) : _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ZapIcon",
      "size": "18"
    }
  }), _vm._v(" Inmediata ")], 1), _vm.ordenData.tipoentrega === 'PG' ? _c('div', {
    staticClass: "mt-2"
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-info",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.ordenData.fechaprogramada)) + " ")]), _c('small', [_vm._v(_vm._s(_vm.formattedHour(_vm.ordenData.fechaprogramada)))])])], 1)], 1) : _vm._e()], 1)]), _c('cerrar-orden-modal', {
    attrs: {
      "orden-data": _vm.ordenData,
      "estado": _vm.ordenData.estadopicking,
      "orden-id": _vm.ordenData.idorden,
      "callback-order": _vm.callbackOrder
    }
  }), _c('cancelar-orden-modal', {
    attrs: {
      "estado": _vm.ordenData.estadopicking,
      "orden-id": _vm.ordenData.idorden,
      "callback-order": _vm.callbackOrder
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }