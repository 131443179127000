<template>
  <div>
    <b-card>

      <template #header>
        <h4 class="mb-0">Estados de Picking</h4>
      </template>
      <app-timeline>
        <app-timeline-item
          v-for="(estado, index) in dataEstados"
          :key="'StdPkng'+index"
          :icon="stateIcon(estado.codigo)"
          :variant="stateColor(estado.codigo)"
        >
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h6 class="m-0">{{ estado.descripcion }}</h6>
          </div>
          <p>{{ formattedDate(estado.fecha) }}</p>
        </app-timeline-item>
      </app-timeline>
      <b-button
        v-if="false"
        block
        class="mt-2"
        @click="showModalCambioEstado"
        variant="primary"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Cambiar Estado</div>
      </b-button>

      <b-modal
        id="modal-cambio-estado"
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cancelar"
        centered
        ref="modal-cambio"
        title="Cambio de estado"
        @hide="initDefaultValue"
        @ok="changeOrderStatus"
      >
        <b-form-group>
          <b-form-radio-group
            v-model="selectedState"
            :options="options"
            name="radios-stacked"
            stacked
          />
        </b-form-group>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BFormGroup,
  BFormRadioGroup,
  BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BFormGroup,
    AppTimeline,
    AppTimelineItem,
    BFormRadioGroup,
    ToastificationContent,
  },
  data() {
    return {
      dataEstados:[],
      options: [
        { text: "Pendiente", value: "CR" },
        { text: "En preparacion / Aprobada", value: "EP" },
        { text: "Preparada", value: "PR" },
        { text: "Enviada / En transporte", value: "EN" },
        { text: "Llego", value: "LL" },
        { text: "No entregada", value: "NE" },
        { text: "Entregada", value: "ET" },
        { text: "Cancelada", value: "AN" },
      ],
      loading: false,
      selectedState: null,
    };
  },
  props: {
    ordenId: {
      type: Number,
      required: true,
    },
    estadosData: {
      type: Array,
      required: true,
    },
    callbackOrder: {
      type: Function,
      required: true,
    },
  },
  methods: {
    unsetEstados(){
      var aux = this.dataEstados[0];
      this.dataEstados = [aux];
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY HH:mm a");
    },
    stateIcon(state) {
      switch (state) {
        case "CR":
          return "UserPlusIcon";
        case "PG":
          return "DollarSignIcon";
        case "EP":
          return "PackageIcon";
        case "C":
          return "ThumbsUpIcon";
        case "IN":
          return "AlertTriangleIcon";
        case "CE":
          return "CheckIcon";
        case "AN":
        case "ND":
        case "NE":
          return "XCircleIcon";
        default:
          return "CheckCircleIcon";
      }
    },
    stateColor(state) {
      switch (state) {
        case "AN":
        case "ND":
        case "NE":
          return "danger";
        case "IN":
          return "warning";
        default:
          return "success";
      }
    },
    changeOrderStatus() {
      this.loading = true;
      const self = this;
      axios
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/ActualizaEstadoOrden",
          body: JSON.stringify({
            empresa: 2,
            idOrden: self.ordenId,
            estado: self.selectedState,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.loading = false;
          if (res.data.bOk) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Éxito",
                icon: "CheckIcon",
                text: "Se cambio correctamente el estado de la orden.",
                variant: "success",
              },
            });
            self.callbackOrder();
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text: `Ocurrió un error al cambiar el estado de la orden(${res.data.mensaje}).`,
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al cambiar el estado de la orden(${e.message}).`,
              variant: "danger",
            },
          });
          self.loading = false;
        });
    },
    showModalCambioEstado(e) {
      this.$refs["modal-cambio"].show();
    },
    initDefaultValue() {
      const lastState = this.estadosData[this.estadosData.length - 1];
      this.selected = { title: lastState.descripcion, value: lastState.codigo };
    },
  },
  beforeMount() {
    this.dataEstados = this.estadosData;
    this.initDefaultValue();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.custom-radio {
  margin: 10px;
}
</style>
