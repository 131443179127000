<template>
    <b-card title="Datos del Picking" class="datos-picking">
      <b-row>
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="UserIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Nombre</small>
              <p class="mhj">
                {{ getNombre }}
                {{ getApellido }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
  
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="CreditCardIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Cédula/RUC</small>
              <p class="mhj">
                {{ getIdentificacion }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
  
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="MailIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Email</small>
              <p class="mhj">
                {{ getEmail }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
  
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="PhoneIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Teléfono</small>
              <p class="mhj">
                {{ getTelefono }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BMediaBody,
    BMediaAside,
  } from "bootstrap-vue";

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BMedia,
      BBadge,
      BButton,
      BAvatar,
      BMediaBody,
      BMediaAside,
    },
    props: {
      ordenData: {
        type: Object,
        required: true,
      },
    },
    computed: {
      getNombre() {
        return this.ordenData.picking?.length > 0 ? this.ordenData.picking[0].nombre : '';
      },
      getApellido() {
        return this.ordenData.picking?.length > 0 ? this.ordenData.picking[0].apellido : '';
      },
      getTipoIdentificacion() {
        return this.ordenData.picking?.length > 0 ? this.ordenData.picking[0].tipoidentifica : '';
      },
      getIdentificacion() {
        return this.ordenData.picking?.length > 0 ? this.ordenData.picking[0].identificacion : '';
      },
      getTelefono() {
        return this.ordenData.picking?.length > 0 ? this.ordenData.picking[0].telefono : '';
      },
      getEmail() {
        return this.ordenData.picking?.length > 0 ? this.ordenData.picking[0].email : '';
      }
    },
  };
  </script>
  
  <style>
  .mhj {
    margin: 0;
    font-size: 12px;
    line-height: 8px;
    font-weight: bold;
  }
  .datos-picking .media {
    margin-bottom: 8px;
  }
  </style>
  