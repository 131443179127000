<template>
  <b-card title="Productos">
    <!-- table -->
    <vue-good-table
      compactMode
      :columns="columns"
      :rows="productosData"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
      :row-style-class="rowStyleClassFn"
    >
      <template slot="table-row" slot-scope="props" >
        <!-- Column: Tipo Orden -->
        <div v-if="props.column.field === 'recolectado'" :class="typeClass">
          <b-form-spinbutton
            id="sb-maxmin"
            v-model="props.row.recolectado"
            @change="
              onChangeCant(
                props.row[props.column.field],
                props.row.originalIndex
              )
            "
            min="0"
            :max="props.row.stock"
            size="sm"
            v-if="estado === 'C' || estado === 'IN'"
          />
          <div v-else>{{ props.row.recolectado }}</div>
        </div>

        <!-- Column: Precio  -->
        <div v-else-if="props.column.field === 'precioUnitario'" :class="typeClass">
          ${{ props.row.precioUnitario.toFixed(2) }}
        </div>

        <!-- Column: Subtotal  -->
        <div v-else-if="props.column.field === 'subtotal'" :class="typeClass">
          ${{ props.row.subtotal.toFixed(2) }}
        </div>

        <!-- Column: Otras Columnas -->
        <span v-else :class="typeClass">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BImg,
  BCard,
  BLink,
  BBadge,
  BButton,
  BCardBody,
  BFormRadio,
  BFormSpinbutton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BImg,
    BCard,
    BLink,
    BBadge,
    BButton,
    BCardBody,
    BFormRadio,
    VueGoodTable,
    BFormSpinbutton,
  },
  props: {
    estado: {
      type: String,
      required: false,
    },
    productosData: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      typeClass: 'cnt',
      columns: [
        {
          label: "Código",
          field: "sku",
        },
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Cant.",
          field: "cantidad",
        },
        {
          label: "Recolectado",
          field: "recolectado",
        },
        {
          label: "Stock",
          field: "stock",
        },
        {
          label: "Precio",
          field: "precioUnitario",
        },
        {
          label: "Subtotal",
          field: "subtotal",
        },
      ],
    };
  },
  methods: {
    onChangeCant(changedData, index) {
      this.productosData[index].recolectado = changedData;
    },
    rowStyleClassFn(row) {
      if ( this.estado === 'AN' ) {
        this.typeClass = 'cnt2'
        return
      }
      const { cantidad, recolectado, stock } = row
      if ( cantidad === recolectado) 
        return 'completa'
      if ( stock === 0 )
        return 'incompleta'
      if ( cantidad !== recolectado)
        return 'parcial'
    }
  },
};
</script>


<style  lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
table.vgt-table {
  font-size: 13px !important;
}
.cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.cnt2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.completa {
  background: rgba(4, 168, 44, 0.7);
}
.parcial {
  background: orange;
}
.incompleta {
  background: red;
}

</style>



