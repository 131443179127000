var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "list-view product-checkout mt-0"
  }, [_c('productos-card', {
    attrs: {
      "estado": _vm.ordenData.estadopicking,
      "productos-data": _vm.ordenData.lstProductos
    }
  }), _c('div', {
    staticClass: "checkout-options"
  }, [_c('b-card', {
    attrs: {
      "title": "Detalles de pago"
    }
  }, [_c('div', {
    staticClass: "price-details"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("SubTotal")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v("$" + _vm._s(_vm.ordenData.subtotal.toFixed(2)))])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("IVA")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v("$" + _vm._s(_vm.ordenData.iva.toFixed(2)))])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("Envio")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v(" $" + _vm._s(_vm.ordenData.valor_envio.toFixed(2)) + " ")])]), _vm.ordenData.descuento > 0 ? _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("Descuento")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v(" $" + _vm._s(_vm.ordenData.descuento.toFixed(2)) + " ")])]) : _vm._e()]), _c('hr'), _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title detail-total"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "detail-amt font-weight-bolder"
  }, [_vm._v(" $" + _vm._s(_vm.ordenData.total.toFixed(2)) + " ")])])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }