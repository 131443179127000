<template>
  <b-card title="Datos del Cliente" class="datos-cliente">
    <b-row>
      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="UserIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Nombre</small>
            <p class="mhj">
              {{ ordenData.cliente[0].nombre }}
              {{ ordenData.cliente[0].apellido }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="CreditCardIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Cédula/RUC</small>
            <p class="mhj">
              {{ ordenData.cliente[0].tipoidentifica }} -
              {{ ordenData.cliente[0].identificacion }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="MapIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Dirección</small>
            <p class="mhj">
              {{ ordenData.lstDireccionEntrega[0].direccion }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="FlagIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Referencia</small>
            <p class="mhj">
              {{ ordenData.lstDireccionEntrega[0].detalle }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col cols="12">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="PhoneIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Teléfono</small>
            <p class="mhj">
              {{ ordenData.cliente[0].telefono }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMedia,
  BBadge,
  BButton,
  BAvatar,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BMediaBody,
    BMediaAside,
  },
  props: {
    ordenData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.mhj {
  margin: 0;
  font-size: 12px;
  line-height: 8px;
  font-weight: bold;
}
.datos-cliente .media {
  margin-bottom: 8px;
}
</style>
