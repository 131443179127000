<template>
  <b-card title="Datos del Motorizado" class="datos-motorizado">
    <b-row v-if="motorizadoData.length > 0">
      <b-col
        xl="4"
        cols="21"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex" style="flex-direction: column">
          <b-avatar
            :src="motorizadoData[0].fotorepartidor"
            size="120px"
            style="align-self: center"
            rounded
          />
          <b-button class="mt-1" variant="primary" @click="onclickShowMap">
            Ver mapa
          </b-button>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="8">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="UserIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Nombre</small>
            <p class="mhj">
              {{ motorizadoData[0].nommbrerepartidor }}
              {{ motorizadoData[0].apellidorepartidor }}
            </p>
          </b-media-body>
        </b-media>

        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="CreditCardIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Identificacion</small>
            <p class="mhj">
              {{ motorizadoData[0].indentificadorrepartidor }}
            </p>
          </b-media-body>
        </b-media>

        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="FlagIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Placa</small>
            <p class="mhj">
              {{ motorizadoData[0].placarepartidor }}
            </p>
          </b-media-body>
        </b-media>

        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-secondary" size="30">
              <feather-icon icon="PhoneIcon" size="15" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <small>Teléfono</small>
            <p class="mhj">
              {{ motorizadoData[0].telefonorepartidor }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
    <div class="alg" v-else>
      <feather-icon class="mb-1" icon="AlertOctagonIcon" size="35" />
      <b-alert show variant="info">
        <div class="alert-body">
          <span class="ml-25">Aun no se ha asignado un Motorizado</span>
        </div>
      </b-alert>
    </div>
    <b-modal
      id="modal-center"
      centered
      title="Vertically Centered"
      v-model="showMap"
      ok-only
      ok-title="Accept"
    >
      <l-map :zoom="zoom" :center="center">
        <!-- Use default icon -->
        <l-marker :lat-lng="[47.41322, -1.219482]" />

        <!-- Use icon given in icon property -->
        <l-marker :lat-lng="[47.41322, -1.209482]" :icon="icon" />

        <!-- Create image icon (icon) from l-icon tag -->
        <l-marker :lat-lng="[47.41322, -1.199482]">
          <l-icon>
            <b-img
              :src="require('@/assets/images/misc/leaf-red.png')"
              height="50"
            />
          </l-icon>
        </l-marker>
      </l-map>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BMedia,
  BModal,
  BAlert,
  BButton,
  BAvatar,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
});

export default {
  components: {
    BRow,
    BCol,
    BImg,
    LMap,
    BCard,
    LIcon,
    BMedia,
    BAlert,
    BModal,
    BButton,
    BAvatar,
    LMarker,
    LTileLayer,
    BMediaBody,
    BMediaAside,
  },
  props: {
    urlGacela: {
      type: String,
      default: "",
    },
    motorizadoData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      icon: icon({
        iconUrl: require("@/assets/images/misc/leaf-green.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      showMap: false,
    };
  },
  methods: {
    onclickShowMap(e) {
      e && e.preventDefault();
      window.open(this.urlGacela, "_blank").focus();
    },
  },
};
</script>


<style lang="scss">
.alg {
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mhj {
  margin: 0;
  font-size: 12px;
  line-height: 8px;
  font-weight: bold;
}
.datos-motorizado .media {
  margin-bottom: 10px;
}
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
    width: 400px;
  }
}
</style>