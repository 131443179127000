<template>
  <b-card-group deck class="mb-2">
    <b-card title="Orden" class="card-apply-job">
      <div class="apply-job-package bg-light-primary rounded m-0">
        <h2 class="m-0">
          <sup class="text-body"> <small>#</small> </sup>{{ ordenData.idorden }}
        </h2>
      </div>
      <div class="apply-job-package bg-light-secondary rounded m-0" v-if="ordenData.numero_orden_se1">
        <strong class="m-0">
          Orden Super Easy 1: {{ ordenData.numero_orden_se1 }}
        </strong>
      </div>
      <div
        v-if="ordenData.promocode !== ''"
        class="bg-light-info rounded mt-1 mb-0 p-1"
      >
        <small>Promocode</small>
        <h6 class="mb-0">
          {{ ordenData.promocode }}
        </h6>
      </div>
    </b-card>

    <b-card title="Fecha de Creacion">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="CalendarIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ formattedDate(ordenData.fechacreacion) }}
          </h6>
          <small>{{ formattedHour(ordenData.fechacreacion) }}</small>
        </b-media-body>
      </b-media>
    </b-card>

    <b-card title="Tipo de entrega">
      <h4>
        <b-badge v-if="ordenData.tipoentrega === 'PG'" variant="light-info">
          <feather-icon icon="ClockIcon" size="18" />
          Programada
        </b-badge>
        <b-badge
          v-else-if="ordenData.tipoentrega === 'SA'"
          variant="light-warning"
        >
          <feather-icon icon="ZapOffIcon" size="18" />
          Sin Apuros
        </b-badge>
        <b-badge v-else variant="light-success">
          <feather-icon icon="ZapIcon" size="18" />
          Inmediata
        </b-badge>
        <div v-if="ordenData.tipoentrega === 'PG'" class="mt-2">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-info" size="34">
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ formattedDate(ordenData.fechaprogramada) }}
              </h6>
              <small>{{ formattedHour(ordenData.fechaprogramada) }}</small>
            </b-media-body>
          </b-media>
        </div>
      </h4>
    </b-card>

    <cerrar-orden-modal
      :orden-data="ordenData"
      :estado="ordenData.estadopicking"
      :orden-id="ordenData.idorden"
      :callback-order="callbackOrder"
    />

    <cancelar-orden-modal
      :estado="ordenData.estadopicking"
      :orden-id="ordenData.idorden"
      :callback-order="callbackOrder"
    />
  </b-card-group>
</template>

<script>
import {
  BCard,
  BBadge,
  BMedia,
  BAvatar,
  BButton,
  BCardGroup,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";
import moment from "moment";
import CerrarOrdenModal from "./CerrarOrdenModal.vue";
import CancelarOrdenModal from "./CancelarOrdenModal.vue";

export default {
  components: {
    BCard,
    BBadge,
    BMedia,
    BAvatar,
    BButton,
    BCardGroup,
    BMediaBody,
    BMediaAside,
    CerrarOrdenModal,
    CancelarOrdenModal,
  },
  props: {
    ordenData: {
      type: Object,
      required: true,
    },
    callbackOrder: {
      type: Function,
      required: true,
    },
  },
  methods: {
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(originalDate).format("hh:mm a");
    },
  },
};
</script>

<style>
</style>
