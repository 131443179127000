<template>
  <div>
    <b-card>

      <template #header>
        <h4 class="mb-0">Estados de la Orden</h4>
        <b-button v-if="itCanResetState()" variant="danger" v-b-modal.modal-1>Reiniciar estados</b-button>
      </template>

      <app-timeline>
        <app-timeline-item
          v-for="estado in dataEstados"
          :key="estado.fecha"
          :icon="stateIcon(estado.codigo)"
          :variant="stateColor(estado.codigo)"
        >
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
            <h6 class="m-0">{{ estado.descripcion }}</h6>
          </div>
          <p>{{ formattedDate(estado.fecha) }}</p>
        </app-timeline-item>
      </app-timeline>
    </b-card>
    <b-modal 
      id="modal-1" 
      title="Atención"
      @ok="handleOk"
    >
      <p class="my-4">Esta acci&oacute;n es <strong style="color:red;">irreversible</strong> y reiniciar&aacute; todos los estados de la orden. <strong>Desea continuar?</strong></p>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BButton,
  BSpinner,
  BFormGroup,
  BFormRadioGroup,
} from "bootstrap-vue";
import moment from "moment";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserID, isSuperUser, isSupervisor, isAdministrator, getAuthToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,
    AppTimeline,
    AppTimelineItem,
    BFormRadioGroup,
  },
  data() {
    return {
      dataEstados:[],
      loading: false,
      selectedState: null,
      options: [
        { text: "Pendiente", value: "CR" },
        { text: "En preparacion / Aprobada", value: "EP" },
        { text: "Preparada", value: "PR" },
        { text: "Enviada / En transporte", value: "EN" },
        { text: "Llego", value: "LL" },
        { text: "No entregada", value: "NE" },
        { text: "Entregada", value: "ET" },
        { text: "Cancelada", value: "AN" },
      ],
    };
  },
  props: {
    ordenId: {
      type: Number,
      required: true,
    },
    estadosData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    itCanResetState() {
      return isSupervisor() || isSuperUser() || isAdministrator();
    },
    unsetEstados(){
      var aux = this.dataEstados[0];
      this.dataEstados = [aux];
    },
    sendEliminarEstadosRequest(){
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/ReiniciaEstadoOrdenPicking",
          body: JSON.stringify({
            orden_id: this.ordenId,
            empresa: 2,
            cliente_id: getUserID(),
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.unsetEstados()
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se reiniciaron los estados del pedido`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error [${e.message}].`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.sendEliminarEstadosRequest();
      this.$nextTick(() => {
        this.$bvModal.hide('modal-1')
      })
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY HH:mm a");
    },
    stateIcon(state) {
      switch (state) {
        case "CR":
          return "UserPlusIcon";
        case "PG":
          return "DollarSignIcon";
        case "EP":
          return "PackageIcon";
        case "PR":
          return "ThumbsUpIcon";
        case "EN":
          return "TruckIcon";
        case "LL":
          return "MapPinIcon";
        case "ET":
          return "CheckIcon";
        case "AN":
        case "ND":
        case "NE":
          return "XIcon";
        default:
          return "CheckIcon";
      }
    },
    stateColor(state) {
      switch (state) {
        case "AN":
        case "ND":
        case "NE":
          return "danger";
        default:
          return "success";
      }
    },
  },
  beforeMount() {
    this.dataEstados = this.estadosData;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.custom-radio {
  margin: 10px;
}
.timeline-item:not(:last-of-type) {
  padding-bottom: 1rem !important;
}
</style>
