var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Estados del motorizado"
    }
  }, [_vm.estadosData && _vm.estadosData.length > 0 ? _c('app-timeline', _vm._l(_vm.estadosData, function (estado) {
    return _c('app-timeline-item', {
      key: estado.fecha,
      attrs: {
        "icon": _vm.stateIcon(estado.codigo),
        "variant": _vm.stateColor(estado.codigo)
      }
    }, [_c('div', {
      staticClass: "\n          d-flex\n          flex-sm-row flex-column flex-wrap\n          justify-content-between\n          mb-1 mb-sm-0\n        "
    }, [_c('h6', {
      staticClass: "m-0"
    }, [_vm._v(_vm._s(estado.descripcion))])]), _c('p', [_vm._v(_vm._s(_vm.formattedDate(estado.fecha)))])]);
  }), 1) : _c('b-alert', {
    staticClass: "mt-2",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertOctagonIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("Aun no hay estados de Motorizado")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }