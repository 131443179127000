var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.ordenData === null && !_vm.loading
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No se encontro la orden solicitada, revise el "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'ordenes'
      }
    }
  }, [_vm._v(" listado de ordenes ")]), _vm._v(" para ver otras ordenes ")], 1)]), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_vm.ordenData ? [_c('b-row', {
    staticClass: "row equal-cols"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('orden-card', {
    attrs: {
      "orden-data": _vm.ordenData,
      "callback-order": _vm.callbackChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('estado-orden-card', {
    attrs: {
      "orden-id": _vm.orderID,
      "estados-data": _vm.ordenData.lstEstadosOrden
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('estados-picking-card', {
    attrs: {
      "orden-id": _vm.orderID,
      "estados-data": _vm.ordenData.lstEstadosPicking,
      "callback-order": _vm.callbackChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('estado-motorizado-card', {
    attrs: {
      "estados-data": _vm.ordenData.lstEstadosMotorizado
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "row equal-cols"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('cliente-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('picking-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('motorizado-card', {
    attrs: {
      "url-gacela": _vm.ordenData.tracking_gacela,
      "motorizado-data": _vm.ordenData.lstRepartidor
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "row equal-cols"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('detalles-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1)], 1)] : _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "45vh 0"
    }
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 2), _c('b-modal', {
    ref: "modal-error",
    attrs: {
      "id": "modal-error",
      "title": "Error",
      "ok-only": "",
      "centered": "",
      "ok-title": "Accept",
      "ok-variant": "danger",
      "modal-class": "modal-danger"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }