<template>
  <b-card title="Cancelar orden" v-if="estado === 'CR'">
    <b-button
      block
      class="mt-2"
      @click="cancelOrder"
      variant="danger"
      :disabled="loading"
    >
      <b-spinner v-if="loading" small label="Loading..." />
      <div v-else>Cancelar orden</div>
    </b-button>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BFormSpinbutton,
    ToastificationContent,
  },
  props: {
    ordenId: {
      type: Number,
      required: true,
    },
    estado: {
      type: String,
      required: true,
    },
    callbackOrder: {
      type: Function,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    cancelOrder(event) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Confirma que desea cancelar la orden.", {
          size: "sm",
          title: "Confirmar",
          okTitle: "Cancelar Orden",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            this.$http
              .post(this.$store.state.app.middlewareURL, {
                path: "/Customer/ActualizaEstadoOrden", // TODO: falta el API
                body: JSON.stringify({tokenSesion: getAuthToken()}),
              })
              .then((res) => {
                if (res.data.bOk) {
                  this.callbackOrder();
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      icon: "CheckIcon",
                      text: "Se cancelo correctamente la orden.",
                      title: "Exito",
                      variant: "success",
                    },
                  });
                } else {
                  throw new Error(res.data.mensaje);
                }
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: "AlertTriangleIcon",
                    text: `Ocurrio un error al cancelar la orden [${e.message}].`,
                    title: "Error",
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
    },
  },
};
</script>
<style lang="scss">
</style>
