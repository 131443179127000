<template>
  <b-card title="Estados del motorizado">
    <app-timeline v-if="estadosData && estadosData.length > 0">
      <app-timeline-item
        v-for="estado in estadosData"
        :key="estado.fecha"
        :icon="stateIcon(estado.codigo)"
        :variant="stateColor(estado.codigo)"
      >
        <div
          class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6 class="m-0">{{ estado.descripcion }}</h6>
        </div>
        <p>{{ formattedDate(estado.fecha) }}</p>
      </app-timeline-item>
    </app-timeline>
    <b-alert v-else show variant="info" class="mt-2">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="AlertOctagonIcon" />
        <span class="ml-25">Aun no hay estados de Motorizado</span>
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import { BCard, BAlert } from "bootstrap-vue";
import moment from "moment";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    BCard,
    BAlert,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    estadosData: {
      type: Array,
      required: false,
    },
  },
  methods: {
    formattedDate(originalDate) {
      return moment(originalDate).format("DD/MM/YYYY HH:mm a");
    },
    stateIcon(state) {
      switch (state) {
        case "CR":
          return "UserPlusIcon";
        case "CL":
          return "MapIcon";
        case "EL":
          return "HomeIcon";
        case "SP":
          return "TruckIcon";
        case "LD":
          return "MapPinIcon";
        case "ET":
          return "CheckIcon";
        case "PN":
        case "NE":
        case "CA":
          return "XIcon";
        default:
          return "CheckIcon";
      }
    },
    stateColor(state) {
      switch (state) {
        case "PN":
        case "NE":
        case "CA":
          return "danger";
        default:
          return "success";
      }
    },
  },
};
</script>

<style>
</style>
