var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.estado === 'CR' ? _c('b-card', {
    attrs: {
      "title": "Cancelar orden"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "danger",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.cancelOrder
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Cancelar orden")])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }